import * as React from 'react';
import { Component } from 'react';
import Layout from "../components/layout"

const AboutPage = () => {
    return (
        <Layout>
            <h1>About Page!</h1>
        </Layout>
    )
}

export default AboutPage